/* DonationPage.js */
import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import DonationRow from '../../pages/DonationPage/DonationRow';
import { DonationFormData } from '../../components/DonationForm/DonationData';
import './DonationPage.css';

function DonationPage() {
  const donationRows = chunkArray(DonationFormData, 3);

  return (
    <div className="donation-container">
      <Navigation />
      <h1>Donation Page</h1>
      {donationRows.map((row, index) => (
        <DonationRow key={index} donationData={row} />
      ))}
      <a id="yemen-temp-page-link" href="./donations/yemen">Yemen</a>
    </div>
  );
}

function chunkArray(arr, n) {
  const result = [];
  for (let i = 0; i < arr.length; i += n) {
    result.push(arr.slice(i, i + n));
  }
  return result;
}

export default DonationPage;
