// NavigationData.js
export const NavigationData = [
    {
      title: "Home",
      path: "/",
      cName: "navigation-text",
    },
    {
      title: "News",
      path: "/news",
      cName: "navigation-text",
    },
    {
      title: "Contact",
      path: "/contact",
      cName: "navigation-text",
    },
    {
      title: "Donate Now",
      path: "/donations",
      cName: "navigation-text",
    },
  ];