/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home-container">
      <Navigation />
      <br></br>
      <h1>Home Page</h1>
      <br></br>
      <div className="wow-widget" data-cmp="88" data-type="full"></div>

    </div>
  );
};

export default HomePage;