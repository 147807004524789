import React from 'react';
import Navigation from '../../../components/Navigation/Navigation';
import './Yemen.css';

export const FormData = [{
        location: "Yemen",
        title: "Nourishment Donation",
        path: "/donations/yemen",
        source: "https://donorbox.org/embed/nourishment-donation-yemen",
    }];

export default function Yemen() {
  return (
    <div className="yemen-container">
      
      <Navigation />
      <br></br>
      
      <h1>Yemen Page</h1>
      
      <div className="DonationForm">
        
        <h2 className="donation-title">{FormData.title}</h2>
        <h3 className="donation-location">{FormData.location}</h3>
       
        <h2>Overburdened by the COVID-19 pandemic and the ongoing conflict, Yemen is facing a severe humanitarian crisis. Millions of people are in need of food, water, and medical assistance. Your donation can help provide nourishment and support to those affected by the crisis in Yemen.</h2> 
       
        <script type="text/javascript" defer="" src="https://donorbox.org/install-popup-button.js" id="donorbox-popup-button-installer"></script>
        
        <a className="dbox-donation-button" href="https://donorbox.org/nourishment-donation-yemen?default_interval=o&amp;amount=30&amp;hide_donation_meter=false">
        
        <img src="https://donorbox.org/images/white_logo.svg" alt="donate_image"/>Donate</a>

        <img src={`${process.env.PUBLIC_URL}/yemen.png`} alt=" yemen_image" className="yemen-image"/>
        
        <section className="context-outer-container">
          <text>Political Crisis and Civil War (2011-Present): The initial part of your text highlights the political crisis that began in 2011 with protests against poverty, unemployment, and corruption. The situation escalated into a civil war involving various entities, including the government, Houthi rebels, and separatist movements. The conflict has led to a severe humanitarian crisis, making Yemen one of the least developed and poorest countries in the region.</text>
        </section>
        
        <section className="context-outer-container">
          <text>Humanitarian Crisis and Global Concerns: The ongoing conflict has resulted in a devastating humanitarian situation, with millions of people in need of aid. Yemen has faced criticism for the impact of the crisis on its people, ranking high on indices measuring fragility and hunger.</text>
        </section>
        
        <section className="context-outer-container">
          <text>emen's Etymology and Historical Geography: The term "Yemen" has roots in Old South Arabian inscriptions and might refer to the southwestern and southern coastlines of the Arabian Peninsula. The historical Yemen covered a broader territory than the current nation. Various etymologies suggest meanings like "South" or "Felicity."</text>
        </section>
       
        <section className="context-outer-container">
          <text>Ancient History: Yemen's history dates back over 7,000 years. It was home to significant civilizations, including the Sabaean Kingdom, known for the Great Dam of Marib. The region played a crucial role in trade, and Yemen became a center of Islamic learning after its conversion in the 7th century.</text>
        </section>
       
        <section className="context-outer-container">
          <text>Himyarite Kingdom: The Himyarite Kingdom, which annexed Sana'a and practiced Rahmanism, faced challenges from external forces like the Romans and internal conflicts. Yemen later fell into chaos, with clans claiming kingship.</text>
        </section>
        
        <section className="context-outer-container">
          <text>Islamic History: The advent of Islam saw Yemen playing a pivotal role. Muhammad sent emissaries to the region, and Yemeni tribes contributed significantly to the Islamic expansion.</text>
        </section>

        <section className="context-outer-container">
          <text>Medieval Dynasties: The Sulayhid dynasty (1047–1138) and Rasulid dynasty (1229–1454) marked periods of stability, cultural development, and architectural achievements. Queen Arwa al-Sulaihi is remembered as a significant ruler.</text>
        </section>
       
        <section className="context-outer-container">
          <text>Ottoman Era: The Ottomans, motivated by strategic and economic interests, conquered Yemen in the 16th century. The highlands, however, remained challenging for Ottoman control, with frequent rebellions and conflicts.</text>
        </section>
        
        <section className="context-outer-container">
          <text>Tahiride Dynasty and Portuguese Involvement: The Tahiride dynasty faced internal weaknesses and external threats, including Portuguese interventions in Aden and Socotra.</text>
        </section>
        
        <section className="context-outer-container">
         <text>Modern History: The Ottoman era saw continued struggles, with Yemen splitting into provinces. The Zaydis and Ottomans engaged in conflicts, and Yemen experienced periods of anarchy and discord.</text>
        </section>

      </div>

    </div>
  );
};