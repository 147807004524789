// DonationRow.js
import React from 'react';
import DonationForm from './../../components/DonationForm/DonationForm';
import './DonationRow.css';

function DonationRow({ donationData }) {
  return (
    <div className="donation-row">
    <br></br>
      {donationData.map((donation, index) => (
        <DonationForm
          key={index}
          location={donation.location}
          title={donation.title}
          source={donation.source}
        />
      ))}
    </div>
  );
}

export default DonationRow;