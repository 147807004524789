import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import './ContactPage.css';

const ContactPage = () => {
  return (
    <div className="contact-container">
        <Navigation />
        <br></br>
        <h1>Contact Page</h1>
        <br></br>
    </div>
  );
};

export default ContactPage;