/* eslint-disable jsx-a11y/iframe-has-title */
// DonationForm.js
import React from 'react';
import './DonationForm.css';

function DonationForm({ location, title, source }) {
  return (
    <div className="DonationForm">
      <h2 className="donation-title">{title}</h2>
      <h3 className="donation-location">{location}</h3>
      <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
      <iframe
        src={source}
        name="donorbox"
        allowpaymentrequest="allowpaymentrequest"
        seamless="seamless"
        frameBorder="0"
        scrolling="no"
        height="500px"
        width="500px"
      ></iframe>
    </div>
  );
}

export default DonationForm;
