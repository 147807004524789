// DonationData.js
const donationDomainArray = ["https://donorbox.org/embed/nourishment-donation-syrian-arab-republic", "https://donorbox.org/embed/nourishment-donation-yemen", "https://donorbox.org/embed/nourishment-donation-syrian-arab-republic", "https://donorbox.org/embed/nourishment-donation-yemen"];

export const DonationFormData = [
    {
        location: "Syrian Arab Republic",
        title: "Nourishment Donation",
        path: "/donation/syrian-arab-republic",
        source: donationDomainArray[0],         
    },
    {
        location: "Yemen",
        title: "Nourishment Donation",
        path: "/donation/yemen",
        source: donationDomainArray[1], 
    },
    {
        location: "[Geographic Location]",
        title: "Nourishment Donation",
        path: "/donation/location3",
        source: donationDomainArray[2], 
    },
    {
        location: "location #4",
        title: "Nourishment Donation - 4",
        path: "/donation/location4",
        source: donationDomainArray[3], 
    },
  ];
  