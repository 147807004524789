import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import NewsPage from './pages/NewsPage/NewsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import DonationPage from './pages/DonationPage/DonationPage';

import DonationForm from './components/DonationForm/DonationForm';
import Yemen from './division/countries/Yemen/Yemen';

// This is the AppRouter component.
function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/donations" element={<DonationPage />} />
        <Route path="/donations/yemen" element={<Yemen />} />
        <Route path="/donations/Syrian-Arab-Republic" element={<DonationForm />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;