import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import './NewsPage.css';

const NewsPage = () => {
  return (
    <div className="news-container">
        <Navigation />
        <br></br>
        <h1>News Page</h1>
        <br></br>
    </div>
  );
};

export default NewsPage;